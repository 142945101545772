const brandingMetaData = document
  .querySelector('meta[name="branding"]')
  .getAttribute('content');
const brandingValues = JSON.parse(brandingMetaData) || {};

const DEFAULT_VALUES = {
  name: '',
  platformName: '',
  helpUrl: '',
  rootUrl: '',
  supportEmail: '',
  landingPage: '',
  logoPath: '',
  logoWidth: '95',
  logoHeight: '20',
};

export const BRANDING = {
  ...DEFAULT_VALUES,
  ...brandingValues,
};
